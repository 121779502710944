import { throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { Uid } from 'state-domains/types';
import { NetworkRequestConfig } from 'state-domains/utils/network/ajax';

import {
    Message,
    MessagesPayload,
    FiltersPayload,
    ResourcesPayload,
    Resource,
    FilterQueryProps,
    Filter,
    DigestPreference,
    DigestPeriod,
} from '../types';

import {
    APIAllMessages,
    APIMessage,
    APIPollMessages,
    APIFilterBody,
    APITopic,
    APIResource,
    APIAllResources,
    APIAllFilters,
    CentralTokenResponse,
    APIDigest,
} from './types';

class TranslationError extends Error {}

export function translateMessage({
    uuid,
    content,
    created_at,
    read,
    topic,
    _links,
    resource,
}: APIMessage): Message {
    const { message, link, extras: { central_instance_name } = {} } = content;
    const instanceUuid = extractCentralUuidFromResource(resource);
    return {
        uuid,
        read,
        topic,
        message,
        link,
        createdAt: created_at,
        self: _links.self,
        centralInstanceName: central_instance_name,
        centralInstanceUuid: instanceUuid ?? undefined,
    };
}

export function translateAllMessages({ count, results, _links }: APIAllMessages): MessagesPayload {
    const messages = results.map(translateMessage);
    const { next, previous, seen } = _links;
    return {
        messages,
        total: count,
        seen: seen ?? undefined,
        next: next ?? undefined,
        prev: previous ?? undefined,
    };
}

export function translateMessagePoll({
    new: total,
    results,
    _links,
}: APIPollMessages): MessagesPayload {
    const messages = results.map(translateMessage);
    return {
        messages,
        total,
        next: _links.next,
        seen: _links.seen,
    };
}

export function translateFilter({
    id,
    resource,
    channel,
    enabled,
    topic,
    _links,
}: APITopic): Filter {
    return {
        id,
        resource,
        channel,
        enabled,
        topic,
        self: _links.self,
    };
}

export function translateFilterList({ results }: APIAllFilters): FiltersPayload {
    const filters = results.map(translateFilter);
    return {
        filters,
    };
}

export function buildTopic(props: FilterQueryProps, enabled: boolean): APIFilterBody {
    return {
        enabled,
        resource: props.resource,
        topic: props.topic,
        channel: props.channel,
    };
}

export function translateResource({ id, resource, _links }: APIResource): Resource {
    return { id, resource, self: _links.self };
}

export function translateResourceList({ results }: APIAllResources): ResourcesPayload {
    const resources = results.map(translateResource);
    return {
        unsubscribers: resources,
    };
}

/**
 * Extracts the notification token from a central response.
 *
 * @param response The Central response object.
 * @throws TranslationError if the token could not be extracted from the response.
 */
export function translateTokenResponse(response: CentralTokenResponse): string {
    if (response?.Token?.id) {
        return response.Token.id;
    }
    throw new TranslationError('Could not extract token from Central response');
}

export function instanceResource(instanceID: string): string {
    return `central:instance.${instanceID}`;
}

export function allProjectResource(instanceID: string): string {
    return `${instanceResource(instanceID)}.project`;
}

export function projectResource(instanceID: string, projectUid: Uid): string {
    return `${allProjectResource(instanceID)}.${projectUid}`;
}

export function extractCentralUuidFromResource(resource: string): string | null {
    const regex = new RegExp(/^central:instance[.]([^.]+)/);
    try {
        const result = regex.exec(resource);
        return result ? result[1] : null;
    } catch (_e) {
        return null;
    }
}

export function getRequestOverrides(token: string): Partial<NetworkRequestConfig> {
    return {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: false,
        crossDomain: true, // WEB-2090 this was added because of an issue with Edge v44
        deferCatch: (error: AjaxError) => throwError(() => error), // Error logging will be handled in `withToken`
    };
}

export function translateApiDigestPreference(pref: APIDigest): DigestPreference {
    return {
        id: pref.id,
        audience: pref.audience,
        resource: pref.resource,
        selfLink: pref._links.self,
        period: pref.period <= 0 ? DigestPeriod.INSTANTLY : DigestPeriod.MINS30,
    };
}
